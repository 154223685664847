import React, { useState, useEffect } from "react";
import { MapPin, Youtube, Link, Phone, Menu, Check } from "lucide-react";
import templeIcon from "../../images/templeName.png";
import templeIconDark from "../../images/templeIconDark.png";
import { Button } from "../../ui/button";
import DrawerRouterContainer from "../layouts/leftDrawer/DrawerRouterContainer";
import axiosInstance from "../../common/constant/axiosInstance";

const AddTempleDetails = ({ handleSave, existingDetails, templeId }) => {
  const [templeName, setTempleName] = useState(
    existingDetails?.temple_name || ""
  );
  const [locationURL, setLocationURL] = useState(
    existingDetails?.temple_map_url || ""
  );
  const [youtubeURL, setYoutubeURL] = useState(
    existingDetails?.event_live_stream_url || ""
  );
  const [websiteURL, setWebsiteURL] = useState(
    existingDetails?.temple_url || ""
  );
  const [mobileNumber, setMobileNumber] = useState(
    existingDetails?.temple_phone || ""
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validateMobileNumber = (number) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(number);
  };

  const handleFormSave = async () => {
    if (!validateMobileNumber(mobileNumber)) {
      setErrorMessage(
        "Mobile number must be exactly 10 digits and contain only numbers."
      );
      return;
    }

    const templeData = {
      temple_name: templeName,
      temple_map_url: locationURL,
      event_live_stream_url: youtubeURL,
      temple_url: websiteURL,
      temple_phone: mobileNumber,
    };

    try {
      const response = await axiosInstance.put(
        `/api/v1/about-temple/${templeId}`,
        templeData
      );
      if (response.data.status) {
        handleSave(response.data.data);
        setSuccessMessage("Data saved successfully!");
        setTimeout(() => setSuccessMessage(""), 2000);
        setErrorMessage("");
      } else {
        setErrorMessage(
          "Failed to update temple details: " + response.data.message
        );
      }
    } catch (error) {
      setErrorMessage("Error updating temple details: " + error.message);
    }
  };

  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setMobileNumber(value);
      if (validateMobileNumber(value)) {
        setErrorMessage("");
      } else {
        setErrorMessage("Mobile number must be exactly 10 digits.");
      }
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (existingDetails) {
      setTempleName(existingDetails.temple_name);
      setLocationURL(existingDetails.temple_map_url);
      setYoutubeURL(existingDetails.event_live_stream_url);
      setWebsiteURL(existingDetails.temple_url);
      setMobileNumber(existingDetails.temple_phone);
    }
  }, [existingDetails]);

  return (
    <div className="flex flex-row space-y-4 sticky w-full justify-evenly items-start ">
      <div className="w-full md:w-[10%] md:flex-col md:items-center iphoneSE:w-[10%] xs:w-[10%] lg:w-1/4 lg:flex lg:flex-col lg:items-start sticky top-0 z-50">
        <div className="xl:hidden flex justify-between items-center w-full mb-4">
          <Button
            onClick={toggleMenu}
            className="text-[#000000] dark:text-[#ffffff]"
          >
            <Menu />
          </Button>
        </div>
        <div
          className={`fixed inset-0 z-40 bg-[#000000] bg-opacity-50 transition-opacity ${
            isMenuOpen ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
          onClick={toggleMenu}
        ></div>
        <div
          className={`fixed inset-y-0 left-0 z-50 bg-[#ffffff] dark:bg-[#000000] w-4/5 max-w-xs transform transition-transform duration-300 ${
            isMenuOpen ? "translate-x-0" : "-translate-x-full"
          } xl:relative xl:translate-x-0 xl:flex xl:w-full xl:max-w-none`}
        >
          <DrawerRouterContainer />
        </div>
      </div>

      <div className="flex flex-col w-full sm:w-full">
        <div className="flex flex-col space-y-2">
          <p className="font-bold text-lg text-left">Temple Name</p>
          <div className="flex items-center">
            <div className="w-11 h-11 border-2 border-[#000000] flex justify-center items-center border-r-0">
              <img
                src={templeIcon}
                alt="temple Icon"
                className="w-6 h-6 dark:hidden"
              />
              <img
                src={templeIconDark}
                alt="temple Icon"
                className="w-6 h-6 dark:flex hidden"
              />
            </div>
            <input
              type="text"
              value={templeName}
              onChange={(e) => setTempleName(e.target.value)}
              className="border-2 border-[#000000] p-2 rounded-md lg:w-[60%] lg:mr-0 md:w-full md:mr-[10%] ss:w-full ss:mr-[10%] iphoneSE:w-full iphoneSE:mr-[10%] xs:w-full xs:mr-[10%] sm:w-full sm:mr-[10%]"
            />
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="font-bold text-lg text-left">Live location URL</p>
          <div className="flex items-center">
            <a
              href={locationURL}
              target="_blank"
              rel="noopener noreferrer"
              className="w-11 h-11 border-2 border-[#000000] flex justify-center items-center border-r-0"
            >
              <MapPin className="w-6 h-6" />
            </a>
            <input
              type="text"
              value={locationURL}
              onChange={(e) => setLocationURL(e.target.value)}
              className="border-2 border-[#000000] p-2 rounded-md lg:w-[60%] lg:mr-0 md:w-full md:mr-[10%] ss:w-full ss:mr-[10%] iphoneSE:w-full iphoneSE:mr-[10%] xs:w-full xs:mr-[10%] sm:w-full sm:mr-[10%]"
            />
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="font-bold text-lg text-left">Live YouTube URL</p>
          <div className="flex items-center">
            <a
              href={youtubeURL}
              target="_blank"
              rel="noopener noreferrer"
              className="w-11 h-11 border-2 border-[#000000] flex justify-center items-center border-r-0"
            >
              <Youtube className="w-6 h-6" />
            </a>
            <input
              type="text"
              value={youtubeURL}
              onChange={(e) => setYoutubeURL(e.target.value)}
              className="border-2 border-[#000000] p-2 rounded-md lg:w-[60%] lg:mr-0 md:w-full md:mr-[10%] ss:w-full ss:mr-[10%] iphoneSE:w-full iphoneSE:mr-[10%] xs:w-full xs:mr-[10%] sm:w-full sm:mr-[10%]"
            />
          </div>
        </div>

        <div className="flex flex-col space-y-2">
          <p className="font-bold text-lg text-left">Website URL</p>
          <div className="flex items-center">
            <a
              href={websiteURL}
              target="_blank"
              rel="noopener noreferrer"
              className="w-11 h-11 border-2 border-[#000000] flex justify-center items-center border-r-0"
            >
              <Link className="w-6 h-6" />
            </a>
            <input
              type="text"
              value={websiteURL}
              onChange={(e) => setWebsiteURL(e.target.value)}
              className="border-2 border-[#000000] p-2 rounded-md lg:w-[60%] lg:mr-0 md:w-full md:mr-[10%] ss:w-full ss:mr-[10%] iphoneSE:w-full iphoneSE:mr-[10%] xs:w-full xs:mr-[10%] sm:w-full sm:mr-[10%]"
            />
          </div>
        </div>

        <div className="flex flex-col space-y-2">
          <p className="font-bold text-lg text-left">Mobile Number</p>
          <div className="flex items-center">
            <div className="w-11 h-11 border-2 border-[#000000] flex justify-center items-center border-r-0">
              <Phone className="w-6 h-6" />
            </div>
            <input
              type="text"
              value={mobileNumber}
              onChange={handleMobileNumberChange}
              className="border-2 border-[#000000] p-2 rounded-md lg:w-[60%] lg:mr-0 md:w-full md:mr-[10%] ss:w-full ss:mr-[10%] iphoneSE:w-full iphoneSE:mr-[10%] xs:w-full xs:mr-[10%] sm:w-full sm:mr-[10%]"
            />
          </div>
          {errorMessage && <p className="text-red-600">{errorMessage}</p>}
        </div>

        <div className="flex justify-center lg:w-[70%] mt-5 xs:w-full">
          <Button
            onClick={handleFormSave}
            className="bg-gray-900 text-[#FFFFFF] rounded-[0.375rem] w-48 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#000000] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md"
          >
            SAVE
          </Button>
        </div>
      </div>

      {successMessage && (
        <div className=" flex flex-row fixed top-20 right-20 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700 text-[#FFFFFF] p-3 rounded shadow-lg z-50 justify-between">
          <div className="w-10">
            <Check />
          </div>
          {successMessage}
        </div>
      )}
    </div>
  );
};

export default AddTempleDetails;
