import axiosInstance from './axiosInstance';

const upload = async (file, bucketName) => {
  if (!file) {
    console.error('No file provided');
    return;
  }

  const formData = new FormData();
  formData.append('file', file);
  formData.append('bucketName', bucketName);

  try {
    const response = await axiosInstance.post('/api/v1/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response.status === 200) {
      console.log('File uploaded successfully', response);
      return response.data.data;
    }
  } catch (error) {
    console.error('Error uploading file:', error);
  }
};

export default upload;
