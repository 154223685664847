import { temples } from "../../common/constant/temple-name";

const getTempleNameById = (templeId) => {
  const temple = temples.find((temple) => temple.temple_id === templeId);
  return temple ? temple.name : "Unknown Temple";
};

const getTempleTelephoneById = (templeId) => {
  const temple = temples.find((temple) => temple.temple_id === templeId);
  return temple ? temple.telephone : "N/A";
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

const formatTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

export const print = async (data) => {
  if (!data || data.length === 0) return;

  const templeId = data[0].user_seva.temple_id;
  const templeName = getTempleNameById(templeId);
  const templeTelephone = getTempleTelephoneById(templeId);

  const transactionDateTime = data[0].user_seva.transaction_date_time;
  const transactionDate = formatDate(transactionDateTime);
  const trasactionTime = formatTime(transactionDateTime);

  const printWindow = window.open("", "_blank");

  if (!printWindow) {
    console.error(
      "Failed to open print window. It may have been blocked by a popup blocker."
    );
    return;
  }

  printWindow.document.write(
    `<html lang="en">
      <head>
        <title>Print Page</title>
        <style>
          body { display: flex; justify-content: center; align-items: center; height: 100vh; margin: 0; }
          table { font-family: Arial, sans-serif; box-shadow: 0px 0px 2px 2px #d2d2d2; padding: 15px; }
        </style>
      </head>
      <body>
        <table width="20%">
          <tr>
            <th colspan="4" style="font-size: 18px; padding: 10px 0px 0px 0px;">${templeName}</th>
          </tr>
          <tr>
            <td colspan="4" style="text-align: center; font-weight: 600;">ದೂರವಾಣಿ : ${templeTelephone}</td>
          </tr>
          <tr>
            <td colspan="4" style="text-align: center; padding: 0px;">-------------------------------------------------------</td>
          </tr>
          <tr>
            <td colspan="4" style="text-align: center; font-weight: 600;">ಸೇವಾ ರಶೀದಿ</td>
          </tr>
          <tr>
            <td colspan="4" style="text-align: center; padding: 10px 0;">-------------------------------------------------------</td>
          </tr>
          <tr>
            <td colspan="1" style="display: flex; height: 20px; padding-top: 10px">ರಶೀದಿ:</td><td style="padding-top: 10px">${
              data[0].user_seva.receipt_number
            }</td>
          </tr>
          <tr>
            <td style="display: flex; height: 20px; padding-top: 10px">ದಿನಾಂಕ:</td><td colspan="2" style="padding-top: 10px">${transactionDate}</td>
          </tr>
           <tr>
            <td style="display: flex; height: 20px; padding-top: 10px">ಸಮಯ:</td><td colspan="2" style="padding-top: 10px">${trasactionTime}</td>
          </tr>
          <tr>
            <td colspan="1" style="display: flex; height: 20px; padding-top: 10px">ನಾಮಧೇಯ:</td><td style="padding-top: 10px">${
              data[0].user.first_name
            }</td>
          </tr>
          <tr>
            <td style="display: flex; height: 20px; padding-top: 10px">ಮೊಬೈಲ್:</td><td style="padding-top: 10px">${
              data[0].user.phone || "ಲಭ್ಯವಿಲ್ಲ"
            }</td>
          </tr>
          <tr>
            <td style="display: flex; height: 20px; padding-top: 10px">ನಕ್ಷತ್ರ:</td><td style="padding-top: 10px">${
              data[0].user.nakshathra || "ಲಭ್ಯವಿಲ್ಲ"
            }</td>
          </tr>
          <tr>
            <td style="display: flex; height: 20px; padding-top: 10px">ರಾಶಿ:</td><td style="padding-top: 10px">${
              data[0].user.rashi || "ಲಭ್ಯವಿಲ್ಲ"
            }</td>
          </tr>
          <tr>
            <td colspan="4" style="text-align: center; padding: 10px 0;">-------------------------------------------------------</td>
          </tr>
          <tr>
            <td style="font-weight: 600;">ಸೇವೆ</td>
            <td style="font-weight: 600; text-align: right;">ಪ್ರಮಾಣ</td>
            <td style="font-weight: 600; text-align: right;">ದರ</td>
            <td style="font-weight: 600; text-align: right;">ಮೊತ್ತ</td>
          </tr>
          <td colspan="4" style="text-align: center; padding: 10px 0;">-------------------------------------------------------</td>
          </tr>`
  );

  data[0]?.user_seva_vivara?.forEach((seva) => {
    printWindow.document.write(
      `<tr>
        <td>${seva.temple_seva_name}:</td>
        <td style="text-align: right;">${seva.seva_units}</td>
        <td style="text-align: right;">${seva.seva_price}</td>
        <td style="text-align: right;">${seva.seva_total}</td>
      </tr>`
    );
  });

  printWindow.document.write(
    `<tr>
      <td colspan="4" style="text-align: center; padding: 10px 0;">-------------------------------------------------------</td>
    </tr>
    <tr>
      <td>ಒಟ್ಟು ಮೊತ್ತ:</td><td style="text-align: right;">${data[0].user_seva_payment_detail.total_amount}</td>
      <td></td><td>${data[0].user_seva_payment_detail.total_amount}</td>
    </tr>
    <tr>
      <td colspan="4" style="text-align: center; padding: 10px 0;">-------------------------------------------------------</td>
    </tr>
    <tr>
      <td colspan="4" style="font-weight: 600;  text-align: center;">ದೇವರ ಅನುಗ್ರಹ ಸದಾ ನಿಮ್ಮ ಮೇಲಿರಲಿ</td>
    </tr>
    </table>
    </body></html>`
  );
  printWindow.document.close();

  setTimeout(() => {
    printWindow.print();
  }, 1000);
};
