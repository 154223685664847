import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input, NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import { Check } from "lucide-react";
import upload from "./../../common/constant/upload";
import { BUTTONS, SUB_TITLES } from "../../common/constant/const";
import "../seva_list/style.css";

const AddSevaModal = ({ sevaData, addSeva, editSeva, onClose }) => {
  const [formData, setFormData] = useState(sevaData || {});
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 412);
  const [imagePreview, setImagePreview] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isImageLoading, setIsImageLoading] = useState(false);

  useEffect(() => {
    setFormData(sevaData || {});
    setImagePreview(sevaData?.seva_photo || "");
  }, [sevaData]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 412);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChange = (event) => {
    let { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formData.id) {
      editSeva(formData);
      setSuccessMessage("Seva updated successfully!");
    } else {
      addSeva(formData);
      setSuccessMessage("Seva added successfully!");
    }
    setTimeout(() => {
      setSuccessMessage(""); 
      onClose(); 
    }, 2000); 
  };

  const findDropDownValue = (value) => {
    return [
      { text: "Yes", value: true },
      { text: "No", value: false },
    ].find((item) => item.value === value);
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    document.getElementById("fileInput").click();
  };

  const handleSevaPhoto = async (event) => {
    setIsImageLoading(true);  
    const bucketName = "mydharmik-seva";
    const uploadedImageUrl = await upload(event, bucketName);
    if (uploadedImageUrl) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        seva_photo: uploadedImageUrl ? uploadedImageUrl : 'gs://mydharmik-seva/default_seva.jpeg',
      }));
      setImagePreview(URL.createObjectURL(event.target.files[0]));
    }
    setIsImageLoading(false);  
  };

  return (
    <Dialog
      title={formData.id ? "Update Seva" : "Add Seva"}
      onClose={onClose}
      className={isSmallScreen ? "custom-dialog-xs" : ""}
    >
      {successMessage && (
        <div className="flex flex-row fixed top-20 right-20 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700 text-[#FFFFFF] p-3 rounded shadow-lg z-50 justify-between">
          <div className="w-10">
            <Check />
          </div>
          {successMessage}
        </div>
      )}
      <style>
        {isSmallScreen &&
          `
          .custom-dialog-xs .k-dialog {
            width: 100% !important;
          }
          .custom-dialog-xs .custom-button-xs {
            width: 300px !important;
          }
        `}
      </style>
      <form onSubmit={handleSubmit} className="space-y-5 p-5 md:p-10">
        <div>
          <label className="block">
            {SUB_TITLES.SEVA_NAME}
          </label>
          <Input
            name="seva_name"
            value={formData.seva_name || ""}
            onChange={handleChange}
            required={true}
            className="w-full bg-[#FFFFFF] dark:bg-[#000000] text-[#000000] dark:text-[#FFFFFF] dark:bg-[#3b3b3b]"
          />
        </div>
        <div>
          <label className="block">{SUB_TITLES.SEVA_PHOTO}</label>
          {imagePreview ? (
            <div className="flex flex-col items-start">
              <img src={imagePreview} alt="Seva Preview" className="w-[100px] h-[100px] mb-2 " />
              <Button
                look="outline"
                className={`bg-gray-900 text-[#FFFFFF] rounded-[0.375rem] w-30 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md ${
                  isSmallScreen ? "custom-button-xs" : ""
                }`}
                onClick={handleButtonClick}
              >
                Change Seva Photo
              </Button>
            </div>
          ) : (
            <Button
              look="outline"
              className={`bg-gray-900 text-[#FFFFFF] rounded-[0.375rem] w-30 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md ${
                isSmallScreen ? "custom-button-xs" : ""
              }`}
              onClick={handleButtonClick} >
              Upload Seva Image
            </Button>
          )}
          <input
            id="fileInput"
            type="file"
            onChange={handleSevaPhoto}
            className="hidden"
          />
        </div>
        <div>
          <label className="block">{SUB_TITLES.SEVA_AVAILABILITY}</label>
          <DropDownList
            data={[
              { text: "Yes", value: true },
              { text: "No", value: false },
            ]}
            textField="text"
            dataItemKey="value"
            value={findDropDownValue(formData.seva_availability)}
            onChange={(event) =>
              setFormData((prevFormData) => ({
                ...prevFormData,
                seva_availability: event.target.value.value,
              }))
            }
            className="w-full dark:bg-[#000000] dark:text-[#FFFFFF] dark:bg-[#3b3b3b]"
          />
        </div>
        <div>
          <label className="block">{SUB_TITLES.SEVA_AMOUNT}</label>
          <NumericTextBox
            name="seva_price"
            type="number"
            value={formData.seva_price}
            onChange={handleChange}
            required={true}
            min={0}
            className="w-full dark:bg-[#000000] dark:text-[#FFFFFF] dark:bg-[#3b3b3b]"
          />
        </div>
        <div>
          <label className="block">{SUB_TITLES.SEVA_COMMENT}</label>
          <TextArea
            name="seva_description"
            multiline={true}
            rows={4}
            value={formData.seva_description || ""}
            onChange={handleChange}
            className="w-full dark:bg-[#000000] dark:text-[#FFFFFF] dark:bg-[#3b3b3b]"
          />
        </div>
        <div className="flex flex-col md:flex-row justify-end space-y-2 md:space-y-0 md:space-x-2 mt-5">
          <Button
            onClick={onClose}
            type="button"
            className={`bg-gray-900 text-[#FFFFFF] rounded-[0.375rem] w-48 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#000000] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md ${
              isSmallScreen ? "custom-button-xs" : ""
            }`}
          >
            {BUTTONS.CLOSE}
          </Button>
          <Button
            onClick={handleSubmit}
            type="submit"
            disabled={isImageLoading}  
            className={`bg-gray-900 text-[#FFFFFF] rounded-[0.375rem] w-48 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#000000] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md ${
              isSmallScreen ? "custom-button-xs" : ""
            }`}
          >
             {formData.id ? "Update" : "Add"}
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export default AddSevaModal;
