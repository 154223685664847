import React, { useEffect, useState } from "react";
import { Button } from "../../../ui/button";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";
import { Check, X } from "lucide-react";
import axiosInstance from "../../../common/constant/axiosInstance";
import { LABELS, BUTTONS, SUB_TITLES } from "../../../common/constant/const";
import upload from "../../../common/constant/upload";

const EditSevaModal = ({ sevaData, onClose, onRefresh, addPrint }) => {
  const userSevaId = sevaData.user_seva_details.user_seva_id;
  const [isSubmitted, setIsSubmitted] = useState(true);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [formData, setFormData] = useState({
    receipt_number: "",
    first_name: "",
    total_amount: 0.0,
    payment_type: "",
    payment_status: "",
    seva_status: "",
    seva_image: null,
    user_seva_completed_image: "",
  });
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (sevaData) {
      setFormData({
        receipt_number: sevaData.user_seva_details.receipt_number,
        first_name: sevaData.user_seva_details.first_name,
        total_amount: sevaData.user_seva_details.total_amount,
        payment_type: sevaData.user_seva_details.payment_type,
        payment_status: sevaData.user_seva_details.payment_status,
        seva_status: sevaData.user_seva_details.seva_status,
        seva_image: null,
        user_seva_completed_image: "",
      });
    }
  }, [sevaData]);

  useEffect(() => {
    if (!sevaData) return;
    if (formData.payment_type !== sevaData.user_seva_details.payment_type) {
      if (formData.payment_type === "cash") {
        setFormData((prevData) => ({
          ...prevData,
          payment_status: "paid",
        }));
      } else if (formData.payment_type === "upi") {
        setFormData((prevData) => ({
          ...prevData,
          payment_status: "success",
        }));
      }
    }
  }, [formData.payment_type, sevaData]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        seva_image: file,
      }));
    }
  };

  const handleSave = async () => {
    setIsImageLoading(true);
    let uploadedImageUrl = formData.user_seva_completed_image;
    if (formData.seva_image) {
      const bucketName = "mydharmik-sevalist";
      uploadedImageUrl = await upload(formData.seva_image, bucketName);
    }

    const formattedFormData = {
      total_amount: formData.total_amount ? parseFloat(formData.total_amount) : 0.0,
      payment_type: formData.payment_type ? formData.payment_type : "",
      payment_status: formData.payment_status ? formData.payment_status : "",
      payment_provider: formData.payment_type === "cash" ? "cash" : "online",
      pay_at_temple: true,
      seva_status: formData.seva_status ? formData.seva_status : "",
      transaction_date_time: new Date().toISOString(),
      user_seva_completed_image: uploadedImageUrl ? uploadedImageUrl : null,
    };

    console.log("FormData being saved:", formattedFormData);

    try {
      const response = await axiosInstance.put(
        `/api/v1/seva-offering/update/${userSevaId}`,
        formattedFormData
      );
      console.log("Response:", response);
      if (response.data.status) {
        setIsSubmitted(false);
        setSuccessMessage("Data updated successfully");
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
        console.log("Success: Data updated successfully");
      } else {
        console.error("Failed to update data", response.data);
      }
    } catch (error) {
      console.error("Error posting data: ", error);
      setSuccessMessage("Failed to update data");
      setTimeout(() => {
        setSuccessMessage("");
      }, 2000);
    } finally {
      setIsImageLoading(false);
    }
  };

  const handlePrint = async () => {
    await addPrint(formData.receipt_number);
    onClose();
    onRefresh();
  };

  const handleClose = async () => {
    onClose();
    onRefresh();
  };

  return (
    <div className="w-full flex flex-col items-center justify-center mt-0">
      {successMessage && (
        <div
          className={`flex flex-row fixed top-20 right-20 p-3 rounded shadow-lg z-50 justify-between bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700 text-[#FFFFFF]`}
        >
          <div className="w-10 flex items-center justify-center">
            {successMessage.startsWith("Failed") ? (
              <X className="text-white" />
            ) : (
              <Check className="text-white" />
            )}
          </div>
          <span>{successMessage}</span>
        </div>
      )}
      <div className="bg-[#ffffff] dark:bg-[#2d2d2d] mt-10 mb-20 p-5">
        <h1 className="text-[#000000] dark:text-[#ffffff] font-bold text-xl ">
          {LABELS.EDIT_SEVA_OFFERING}
        </h1>
        <div>
          <Label className="w-full mr-2 text-base flex justify-between mt-2 mb-2 text-[#000000] dark:text-[#ffffff]">
            {SUB_TITLES.RECEIPT_NUMBER}
          </Label>
          <Input
            value={formData.receipt_number}
            disabled
            className="bg-[#ffffff] text-[#000000] dark:text-[#ffffff] dark:bg-[#3b3b3b]"
          />
        </div>
        <div>
          <Label className="w-full mr-2 text-base flex justify-between mt-2 mb-2 text-[#000000] dark:text-[#ffffff]">
            {SUB_TITLES.USER_NAME}
          </Label>
          <Input
            value={formData.first_name}
            disabled
            className="bg-[#ffffff] text-[#000000] dark:text-[#ffffff] dark:bg-[#3b3b3b]"
          />
        </div>
        <div>
          <Label className="w-full mr-2 text-base flex justify-between mt-2 mb-2 text-[#000000] dark:text-[#ffffff]">
            {SUB_TITLES.TOTAL_AMOUNT}
          </Label>
          <Input
            value={formData.total_amount}
            disabled
            className="bg-[#ffffff] text-[#000000] dark:text-[#ffffff] dark:bg-[#3b3b3b]"
          />
        </div>
        <div>
          <Label className="w-full mr-2 text-base flex justify-between mt-2 mb-2 text-[#000000] dark:text-[#ffffff]">
            {SUB_TITLES.PAYMENT_TYPE}
          </Label>
          <select
            className="form-select mt-1 block w-full p-4/5 border border-[#000000] p-2 dark:bg-[#3b3b3b]"
            value={formData.payment_type}
            onChange={(e) =>
              setFormData({ ...formData, payment_type: e.target.value })
            }
          >
            <option value="cash">Cash</option>
            <option value="upi">UPI</option>
          </select>
        </div>
        <div>
          <Label className="w-full mr-2 text-base flex justify-between mt-2 mb-2 text-[#000000] dark:text-[#ffffff]">
            {SUB_TITLES.PAYMENT_STATUS}
          </Label>
          <select
            className="form-select mt-1 block w-full p-4/5 border border-[#000000] p-2 dark:bg-[#3b3b3b]"
            value={formData.payment_status}
            onChange={(e) =>
              setFormData({ ...formData, payment_status: e.target.value })
            }
          >
            {formData.payment_type === "cash" && (
              <>
                <option value="paid">Paid</option>
                <option value="pending">Pending</option>
              </>
            )}
            {formData.payment_type === "upi" && (
              <>
                <option value="success">Success</option>
                <option value="pending">Pending</option>
                <option value="failure">Failure</option>
              </>
            )}
          </select>
        </div>
        <div>
          <Label className="w-full mr-2 text-base flex justify-between mt-2 mb-2 text-[#000000] dark:text-[#ffffff] ">
            {SUB_TITLES.SEVA_STATUS}
          </Label>
          <select
            className="form-select mt-1 block w-full p-4/5 border border-[#000000] p-2 dark:bg-[#3b3b3b]"
            value={formData.seva_status}
            onChange={(e) =>
              setFormData({ ...formData, seva_status: e.target.value })
            }
          >
            <option value="on-processing">On Processing</option>
            <option value="accepted">Accepted</option>
            <option value="completed">Completed</option>
          </select>
        </div>
        {formData.seva_status === "completed" && (
          <div>
            <Label className="w-full mr-2 text-base flex justify-between mt-2 mb-2 text-[#000000] dark:text-[#ffffff]">
              {SUB_TITLES.user_seva_completed_image}
            </Label>
            <div className="flex items-center">
              <input
              type="file"
              accept="image/*"
                className="hidden"
                id="seva_image"
              onChange={handleFileChange}
            />
              <label
                htmlFor="seva_image"
                className="bg-[#f5f5f5] text-[#000000] dark:bg-[#3b3b3b] dark:text-[#ffffff] border border-[#000000] dark:border-[#ffffff] p-1 rounded cursor-pointer hover:bg-[#e0e0e0] dark:hover:bg-[#4b4b4b] transition-colors"
              >
                Choose File
              </label>
              <span className="ml-2 text-sm text-[#000000] dark:text-[#ffffff]">
                {formData.seva_image
                  ? formData.seva_image.name
                  : "No file chosen"}
              </span>
            </div>
          </div>
        )}
        <div className="flex items-center justify-center pt-5 ">
          <Button
            className="bg-gray-900 dark:bg-[#f5f5f5] dark:text-[#000000] text-[#ffffff] mr-5 hover:bg-gray-700 rounded-[0.375rem] hover:transition-colors dark:hover:bg-[#e0e0e0] w-48 text-sm transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 dark:hover:"
            primary
            onClick={handleSave}
          >
            {BUTTONS.SAVE}
          </Button>
          <Button
            style={{
              backgroundColor: isSubmitted ? "#ccc" : "gray-700",
              color: "[#000000]",
            }}
            primary
            className="mr-5 rounded-[0.375rem] w-48 text-sm transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 dark:text-[#000000]"
            onClick={handlePrint}
            disabled={isSubmitted}
          >
            {BUTTONS.PRINT}
          </Button>
          <Button
            className="bg-gray-900 dark:bg-[#f5f5f5] dark:text-[#000000] text-[#ffffff] mr-5 hover:bg-gray-700 rounded-[0.375rem] hover:transition-colors dark:hover:bg-[#e0e0e0] w-48 text-sm transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 dark:hover:"
            primary
            onClick={handleClose}
          >
            {BUTTONS.CLOSE}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditSevaModal;
