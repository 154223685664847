import {
  LayoutDashboard,
  ScrollText,
  LayoutPanelLeft,
  CalendarFold,
  LogOut
} from "lucide-react";
import temple from "../images/temple.png";
import templeDark from "../images/templeDark.png";
import { LABELS } from "./constant/const";

const navItemClassName =
  "hover:bg-gray-300 dark:hover:bg-gray-600 rounded-full p-3 transition-colors duration-200";

export const NavItems = [
  {
    id: "dashboard",
    title: LABELS.DASHBOARD,
    icon: LayoutDashboard,
    href: "/dashboard",
    className: navItemClassName,
  },
  {
    id: "sevalist",
    title: LABELS.SEVALIST,
    icon: ScrollText,
    href: "/sevalist",
    className: "hidden md:flex lg:flex " + navItemClassName,
  },
  {
    id: "sevamanagement",
    title: LABELS.SEVA_MANAGEMENT,
    icon: LayoutPanelLeft,
    href: "/sevamanagement",
    className: navItemClassName,
  },
  {
    id: "events",
    title: LABELS.EVENTS,
    icon: CalendarFold,
    href: "/events",
    className: navItemClassName,
  },
  {
    id: "abouttemple",
    title: LABELS.ABOUT_TEMPLE,
    icon: () => (
      <div className="w-6 h-6">
        <img src={temple} alt="Temple" className="w-6 h-6 dark:hidden" />
        <img
          src={templeDark}
          alt="Temple"
          className="w-6 h-6 hidden dark:flex"
        />
      </div>
    ),
    href: "/abouttemple",
    className: navItemClassName,
  },
  {
    id: "logout",
    title: LABELS.LOGOUT,
    icon: LogOut,
    href: "/logout",
    className: navItemClassName,
  },
];
