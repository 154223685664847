import React from "react";
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
} from "@progress/kendo-react-charts";
import { LABELS } from "../../common/constant/const";

const series = [
  { category: "Panchakajaya", value: 30 },
  { category: "Kunkumarchane", value: 30 },
  { category: "Karthikapuje", value: 10 },
  { category: "Ranga Pooje", value: 30 },
];

const labelContent = (props) => `${props.dataItem.category}`;

const DailySevaRevenue = () => {
  return (
    <Chart>
      <ChartTitle text={LABELS.DAILY_POOJA_SEVA_REVENUE} />
      <ChartLegend position="bottom" />
      <ChartSeries>
        <ChartSeriesItem
          type="pie"
          data={series}
          field="value"
          categoryField="category"
          labels={{
            visible: true,
            content: labelContent,
          }}
        />
      </ChartSeries>
    </Chart>
  );
};

export default DailySevaRevenue;
