import React from 'react';

const SevaCard = ({ seva, onEdit, onDelete }) => {
  return (
    <div className="card-container bg-[#FFFFFF] dark:bg-gray-800 shadow-md rounded-lg p-4 mb-4">
      <div className="card-header flex justify-between items-center mb-2">
        <h3 className="card-title font-bold text-lg">{seva.seva_name}</h3>
        <div className="card-actions flex">
          <span
            className="k-icon k-font-icon k-i-edit text text-xl mr-2 cursor-pointer"
            onClick={() => onEdit(seva)}
          ></span>
          <span
            className="k-icon k-font-icon k-i-delete text text-xl cursor-pointer"
            onClick={() => onDelete(seva)}
          ></span>
        </div>
      </div>
      <div className="card-body">
        <p><strong>ID:</strong> {seva.id}</p>
        <p><strong>Availability:</strong> {seva.seva_availability ? "Available" : "Not Available"}</p>
        {seva.seva_photo && <img src={seva.seva_photo} alt="seva" className="w-12 h-12 mb-2" />}
        <p><strong>Amount:</strong> {seva.seva_price}</p>
        <p><strong>Comments:</strong> {seva.seva_description}</p>
      </div>
    </div>
  );
};

export default SevaCard;
