import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { Navigate, Route, Routes } from 'react-router-dom';

import Dashboard from '../../components/dashboard';
import Login from '../../components/login';
import Events from '../../components/seva_events';
import SevaList from '../../components/seva_list';
import SevaManagement from '../../components/seva_management';
import Logout from '../../components/logout/logout';
import AboutTemple from '../../components/about_temple';


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsLoggedIn(!!user);
    });
    return () => unsubscribe();
  }, []);

  return (
    <div className="app-container">

      <div className="content">
        <Routes>
          <Route path="/" element={isLoggedIn ? <Dashboard /> : <Login />} />
          <Route path="/dashboard" element={isLoggedIn ? <Dashboard /> : <Navigate to="/" />} />
          <Route path="/sevalist" element={isLoggedIn ? <SevaList /> : <Navigate to="/" />} />
          <Route path="/sevamanagement" element={isLoggedIn ? <SevaManagement /> : <Navigate to="/" />} />
          <Route path="/events" element={isLoggedIn ? <Events /> : <Navigate to="/" />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/abouttemple"  element={isLoggedIn ? <AboutTemple /> : <Navigate to="/" />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
