import React, { useEffect, useState } from "react";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Skeleton } from "@progress/kendo-react-indicators";
import { Menu } from "lucide-react";
import { Button } from "../../ui/button";

import axiosInstance from "../../common/constant/axiosInstance";
import DrawerRouterContainer from "../layouts/leftDrawer/DrawerRouterContainer";
import templeID from "./../../common/constant/temple-id";
import AddEventsModal from "./addeventsmodal";
import ConfirmModal from "./confirmmodal";
import { LABELS, BUTTONS } from "../../common/constant/const";

const Events = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState({ skip: 0, take: 20 });
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selectedRowForDelete, setSelectedRowForDelete] = useState(null);
  const [pageSizeValue, setPageSizeValue] = useState();
  const [sort, setSort] = useState([]);
  const [templeId, setTempleId] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    fetchData();
  }, [templeId]);

  useEffect(() => {
    const fetchTempleId = async () => {
      try {
        const fetchedTempleId = await templeID();
        setTempleId(fetchedTempleId);
        console.log("Fetched Temple ID:", fetchedTempleId);
      } catch (error) {
        console.error("Error fetching temple ID:", error);
      }
    };

    fetchTempleId();
  }, []);

  const fetchData = async () => {
    try {
      if (!templeId) return;
      const response = await axiosInstance.get(
        `/api/v1/temple-event/${templeId}`
      );
      console.log(response.data.data);
      if (Array.isArray(response.data.data)) {
        setRows(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
        console.error("Data format error: Response data is not an array");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const openEditModal = (dataItem) => {
    setSelectedEvent(dataItem);
    setShowModal(true);
  };

  const closeEditModal = () => {
    setSelectedEvent(null);
    setShowModal(false);
  };

  const addEvent = async (eventData) => {
    try {
      const response = await axiosInstance.post(
        `/api/v1/temple-event/${templeId}`,
        eventData
      );
      if (response.data) {
        fetchData();
      }
    } catch (error) {
      console.error("Error adding Event:", error);
    }
  };

  const editEvent = async (eventData) => {
    try {
      const { event_photo, event_invitation, ...payload } = eventData;
      console.log("event data:", eventData);
      const response = await axiosInstance.put(
        `/api/v1/temple-event/${eventData.id}`,
        eventData
      );
      if (response.data) {
        fetchData();
      }
    } catch (error) {
      console.error("Error editing Event:", error);
    }
  };

  const handlePageChange = (event) => {
    const targetEvent = event.targetEvent;
    const take = targetEvent.value === "All" ? rows.length : event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleSortChange = (event) => {
    setSort(event.sort);
  };

  const processedData = process(rows, {
    skip: page.skip,
    take: page.take,
    sort,
  });

  const columnStyles = {
    color: "#673011",
    borderTop: "1px solid #000",
  };

  const openAddModal = () => {
    setIsAddModalOpen(true);
  };

  const openDeleteConfirmation = (dataItem) => {
    setSelectedRowForDelete(dataItem);
    setIsConfirmModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      console.error("Deleting Event:", selectedRowForDelete);
      await axiosInstance.delete(
        `/api/v1/temple-event/${selectedRowForDelete.id}`
      );
      setIsConfirmModalOpen(false);
      fetchData();
    } catch (error) {
      console.error("Error deleting Event:", error);
    }
  };

  const LinkCell = (props) => {
    return (
      <td>
        <a
          href={props.dataItem[props.field]}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.dataItem[props.field]}
        </a>
      </td>
    );
  };

  const TimeCell = (props) => {
    const time = props.dataItem[props.field];
    const formattedTime = `${time.hours
      .toString()
      .padStart(2, "0")}:${time.minutes.toString().padStart(2, "0")}`;
    return <td>{formattedTime}</td>;
  };

  return (
    <div>
      <div className="w-full flex flex-col xl:flex-row items-start">
        <div className="w-full xl:w-1/5  flex flex-col items-start sticky top-0 z-50">
          <div className="xl:hidden flex justify-between items-center w-full mb-4">
            <Button
              onClick={toggleMenu}
              className="text-[#000000] dark:text-[#ffffff]"
            >
              <Menu />
            </Button>
          </div>
          <div
            className={`fixed inset-0 z-40 bg-[#000000] bg-opacity-50 transition-opacity ${
              isMenuOpen ? "opacity-100" : "opacity-0 pointer-events-none"
            } xl:hidden`}
            onClick={toggleMenu}
          ></div>
          <div
            className={`fixed inset-y-0 left-0 z-50 bg-[#ffffff] dark:bg-[#000000] w-4/5 max-w-xs transform transition-transform duration-300 ${
              isMenuOpen ? "translate-x-0" : "-translate-x-full"
            } xl:relative xl:translate-x-0 xl:flex xl:w-full xl:max-w-none`}
          >
            <DrawerRouterContainer />
          </div>
        </div>
        <div className="w-full mr-8  xl:flex flex-col">
          {isAddModalOpen && (
            <AddEventsModal
              eventData={null}
              addEvent={addEvent}
              editEvent={editEvent}
              onClose={() => setIsAddModalOpen(false)}
            />
          )}
          {showModal && selectedEvent && (
            <AddEventsModal
              eventData={selectedEvent}
              addEvent={addEvent}
              editEvent={editEvent}
              onClose={closeEditModal}
            />
          )}
          {isConfirmModalOpen && (
            <ConfirmModal
              message={`Are you sure you want to delete this Event?`}
              onConfirm={confirmDelete}
              onCancel={() => setIsConfirmModalOpen(false)}
            />
          )}
          <div className="grid-wrapper w-full  ">
            {loading ? (
              <Skeleton shape={"rectangle"} />
            ) : rows.length > 0 ? (
              <Grid
              className="mt-4" 
                resizable
                data={processedData.data}
                skip={page.skip}
                take={page.take}
                total={rows.length}
                pageable={{
                  buttonCount: 4,
                  pageSizes: [5, 10, 15, 20, "All"],
                  pageSizeValue: pageSizeValue,
                }}
                onPageChange={handlePageChange}
                sortable={true}
                sort={sort}
                onSortChange={handleSortChange}
              >
                <Column
                  field="id"
                  title="ID"
                  style={columnStyles}
                  width={185}
                />
                <Column
                  field="event_name"
                  title="Name"
                  style={columnStyles}
                  width={185}
                />
                <Column
                  field="event_date"
                  title="Date"
                  style={columnStyles}
                  width={185}
                />
                <Column
                  field="event_time"
                  title="Time"
                  style={columnStyles}
                  cell={TimeCell}
                  width={185}
                />
                <Column
                  field="event_description"
                  title="Comments"
                  style={columnStyles}
                  width={185}
                />
                <Column
                  field="event_photo"
                  title="Event"
                  width={185}
                  cell={(props) => (
                    <td>
                      <img
                        src={props.dataItem.event_photo}
                        alt="event"
                        className="w-12 h-12"
                      />
                    </td>
                  )}
                  style={columnStyles}
                />
                <Column
                  field="event_invitation"
                  title="Invitation"
                  width={185}
                  cell={(props) => (
                    <td>
                      <img
                        src={props.dataItem.event_invitation}
                        alt="event"
                        className="w-12 h-12"
                      />
                    </td>
                  )}
                  style={columnStyles}
                />
                <Column
                  field="event_live_stream_url"
                  title="Live URL"
                  style={columnStyles}
                  cell={LinkCell}
                  width={185}
                />
                <Column
                  title="Actions"
                  cell={(props) => (
                    <td>
                      <div className="flex">
                        <span
                          className="k-icon k-font-icon k-i-edit    text-[20px] mr-2.5 cursor-pointer"
                          onClick={() => openEditModal(props.dataItem)}
                        ></span>
                        <span
                          className="k-icon k-font-icon k-i-delete  text-[20px] mr-2.5 cursor-pointer"
                          onClick={() => openDeleteConfirmation(props.dataItem)}
                        ></span>
                      </div>
                    </td>
                  )}
                  width={100}
                />
              </Grid>
            ) : (
              <div className="text-center p-5">{LABELS.NO_DATA_FOUND}</div>
            )}
          </div>
          <div className="sevaAddbtn mt-5 ml-auto text-center">
            <Button
              className="bg-gray-900 text-[#FFFFFF] mb-10 mr-5 rounded-[0.375rem] w-48 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 pl-4 pr-4 pt-2 pb-2 hover:bg-gray-700 hover:border-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#000000] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md"
              onClick={openAddModal}
            >
              {BUTTONS.ADD}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Events;
