import { Button } from "../../ui/button";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import React, { useEffect, useState } from "react";

import axiosInstance from "../../common/constant/axiosInstance";
import backgroundimg from "../../images/backgroundimg-white.png";

import googleIcon from "../../images/googleIcon.png";
import launcher_icon from "../../images/launcher_icon.png";
import { BUTTONS } from "../../common/constant/const";
import { ModeToggle } from "../../ui/mode-toggle";

const Login = ({ onLogin }) => {
  const [error, setError] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      getAnalytics();
    }
  }, []);

  const handleGoogleSignIn = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const displayNameParts = user.displayName.trim().split(" ");
      const first_name = displayNameParts[0] || "";
      const last_name = displayNameParts.slice(1).join(" ") || "";

      const response = await axiosInstance.post("/api/v1/users", {
        first_name: first_name,
        last_name: last_name,
        email: user.email || "",
        external_id: user.uid,
      });

      console.log("Response:", response);

      if (response.status === 200) {
        localStorage.setItem("userId", response.data.data.id);
        onLogin();
      } else {
        console.error("Failed to create or authenticate user:", response.data);
      }
    } catch (error) {
      console.error(error.message);
      setError("Failed to sign in with Google. Please try again.");
    }
  };

  return (
    <>
    
    <div className="flex h-screen overflow-hidden bg-[#ffffff] dark:bg-[#000000] relative">
      
      <div className="hidden md:flex-1 bg-cover bg-center relative  md:hidden xl:flex">
        <img
          src={backgroundimg}
          className="w-full h-full object-cover"
          alt="backgroundImage"
        />
      </div>
      <div className="flex flex-1 justify-center flex-col items-center md:w-full ">
      <div className="w-full flex text-[#000000] justify-end mt-0 absolute top-4 left-0 dark:text-[#FFFFFF]">
          <ModeToggle />
        </div>
        <div className=" flex flex-col items-center w-11/12 max-w-md p-6 space-y-4 bg-[#ffffff] shadow-2xl rounded-2xl md:w-3/6 md:space-y-6 md:p-8 ">
          <div className="flex items-center">
            <img
              src={launcher_icon}
              width={120}
              height={120}
              alt="logo"
              className="mb-4 shadow-xl rounded-full"
            />
          </div>
          <Button
            onClick={handleGoogleSignIn}
            className="w-[265px] flex items-center justify-around p-2 text-lg font-medium bg-[#ffffff] text-[#000000] rounded-full border border-gray-300 transition-transform duration-300 ease-in-out shadow-xl hover:cursor-pointer hover:shadow-2xl hover:bg-gray-200 hover:scale-105"
          >
            <img src={googleIcon} width={30} height={30} alt="Google Icon" />
            <span className="ml-4">{BUTTONS.SIGN_IN_WITH_GOOGLE}</span>
          </Button>

          {error && <div className="text-[#FF0000] mt-4">{error}</div>}
        </div>
      </div>
    </div>
    </>
  );
};

export default Login;
