export const temples = [
  {
    temple_id: 1,
    name: "ಶ್ರೀ ಶಿವಶಾಂತಿಕಾ ಪರಮೇಶ್ವರಿ ದೇವಾಲಯ,ಕಿತ್ರೆ ದೇವಿಮನೆ, ಮಾರುಕೇರಿ, ಭಟ್ಕಳ - 581320",
    telephone: "83852905570"
  },
  {
    temple_id: 2,
    name: "Temple 2",
    telephone: "987-654-3210"
  }
];
