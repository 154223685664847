import React from "react";
import {
  Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisTitle,
  ChartCategoryAxisItem,
} from "@progress/kendo-react-charts";
import { LABELS, SUB_TITLES } from "../../common/constant/const";

const firstSeries = [100, 123, 45, 87];
const secondSeries = [120, 67, 124, 154];
const categories = ["Q1", "Q2", "Q3", "Q4"];

const DailyActiveUsers = () => {
  return (
    <Chart>
      <ChartTitle text={LABELS.DAILY_ACTIVE_USERS} />
      <ChartCategoryAxis>
        <ChartCategoryAxisItem categories={categories}>
          <ChartCategoryAxisTitle text={SUB_TITLES.MONTHS} />
        </ChartCategoryAxisItem>
      </ChartCategoryAxis>
      <ChartSeries>
        <ChartSeriesItem type="bar" gap={2} data={firstSeries} />
        <ChartSeriesItem type="bar" data={secondSeries} />
      </ChartSeries>
    </Chart>
  );
};

export default DailyActiveUsers;
