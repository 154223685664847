import React, { useState, useEffect } from "react";
import AddTempleDetails from "./addtempledetailsmodal";
import axiosInstance from "../../common/constant/axiosInstance";

const AboutTemple = () => {
  const [storedDetails, setStoredDetails] = useState([]);
  const [selectedTemple, setSelectedTemple] = useState(null);
  const [templeId, setTempleId] = useState(1);

  useEffect(() => {
    const fetchTempleDetails = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/v1/about-temple/${templeId}`
        );
        if (response.data.status) {
          setStoredDetails([response.data.data]);
        } else {
          console.error(
            "Failed to fetch temple details:",
            response.data.message
          );
        }
      } catch (error) {
        console.error("Error fetching temple details:", error);
      }
    };

    fetchTempleDetails();
  }, [templeId]);

  const handleSave = (newEntry) => {
    setStoredDetails([newEntry]);
    setSelectedTemple(newEntry);
  };

  return (
    <div className="flex flex-col">
      <div className="flex">
        <AddTempleDetails
          handleSave={handleSave}
          existingDetails={storedDetails[0]}
          templeId={templeId}
        />
      </div>
    </div>
  );
};

export default AboutTemple;
