import React, { useEffect, useState } from "react";
import { process } from "@progress/kendo-data-query";
import { Button } from "../../ui/button";
import { GridColumn as Column, Grid } from "@progress/kendo-react-grid";
import { Skeleton } from "@progress/kendo-react-indicators";
import axiosInstance from "../../common/constant/axiosInstance";
import DrawerRouterContainer from "../layouts/leftDrawer/DrawerRouterContainer";
import templeID from "./../../common/constant/temple-id";
import AddSevaModal from "./addsevamodal";
import ConfirmModal from "./confirmmodal";
import { BUTTONS } from "../../common/constant/const";
import { Menu } from "lucide-react";
import SevaCard from "./SevaCard";

import "@progress/kendo-theme-default/dist/all.css";

const SevaManagement = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedSeva, setSelectedSeva] = useState(null);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState({ skip: 0, take: 10 });
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selectedRowForDelete, setSelectedRowForDelete] = useState(null);
  const [pageSizeValue, setPageSizeValue] = useState();
  const [sort, setSort] = useState([]);
  const [templeId, setTempleId] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    fetchData();
  }, [templeId]);

  useEffect(() => {
    const fetchTempleId = async () => {
      try {
        const fetchedTempleId = await templeID();
        setTempleId(fetchedTempleId);
        console.log("Fetched Temple ID:", fetchedTempleId);
      } catch (error) {
        console.error("Error fetching temple ID:", error);
      }
    };

    fetchTempleId();
  }, []);

  const fetchData = async () => {
    try {
      if (!templeId) return;
      const response = await axiosInstance.get(
        `/api/v1/temple-seva/${templeId}`
      );
      console.log(response.data.data);
      if (Array.isArray(response.data.data)) {
        setRows(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
        console.error("Data format error: Response data is not an array");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const openEditModal = (dataItem) => {
    setSelectedSeva(dataItem);
    setShowModal(true);
  };

  const closeEditModal = () => {
    setSelectedSeva(null);
    setShowModal(false);
  };

  const addSeva = async (sevaData) => {
    try {
      const response = await axiosInstance.post(
        `/api/v1/temple-seva/${templeId}`,
        sevaData
      );
      if (response.data) {
        fetchData();
      }
    } catch (error) {
      console.error("Error adding Seva:", error);
    }
  };

  const editSeva = async (sevaData) => {
    try {
      const response = await axiosInstance.put(
        `/api/v1/temple-seva/${sevaData.id}`,
        sevaData
      );
      if (response.data) {
        fetchData();
      }
    } catch (error) {
      console.error("Error editing Seva:", error);
    }
  };

  const handlePageChange = (event) => {
    const targetEvent = event.targetEvent;
    const take = targetEvent.value === "All" ? rows.length : event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleSortChange = (event) => {
    setSort(event.sort);
  };

  const processedData = process(rows, {
    skip: page.skip,
    take: page.take,
    sort,
  });

  const columnStyles = {
    color: "#673011",
    borderTop: "1px solid #000",
  };

  const openAddModal = () => {
    setIsAddModalOpen(true);
  };

  const openDeleteConfirmation = (dataItem) => {
    setSelectedRowForDelete(dataItem);
    setIsConfirmModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      console.error("Deleting Seva:", selectedRowForDelete);
      await axiosInstance.delete(
        `/api/v1/temple-seva/${selectedRowForDelete.id}`
      );
      setIsConfirmModalOpen(false);
      fetchData();
    } catch (error) {
      console.error("Error deleting Seva:", error);
    }
  };

  return (
    <div className="relative">
      <div className="w-full flex xl:flex-row xs:flex-col md:flex-col justify-evenly items-start">
        <div className="w-full xl:w-1/5 flex flex-col items-start sticky top-0 z-50">
          <div className="xl:hidden flex justify-between items-center w-full mb-4">
            <Button
              onClick={toggleMenu}
              className="text-[#000000] dark:text-[#ffffff]"
            >
              <Menu />
            </Button>
          </div>
          <div
            className={`fixed inset-0 z-40 bg-[#000000] bg-opacity-50 transition-opacity ${
              isMenuOpen ? "opacity-100" : "opacity-0 pointer-events-none"
            } xl:hidden`}
            onClick={toggleMenu}
          ></div>
          <div
            className={`fixed inset-y-0 left-0 z-50 bg-[#ffffff] dark:bg-[#000000] w-4/5 max-w-xs transform transition-transform duration-300 ${
              isMenuOpen ? "translate-x-0" : "-translate-x-full"
            } xl:relative xl:translate-x-0 xl:flex xl:w-full xl:max-w-none`}
          >
            <DrawerRouterContainer />
          </div>
        </div>
        <div className="grid-container w-full sm:w-full sm:pl-4 flex flex-col items-start relative z-30">
          {isAddModalOpen && (
            <AddSevaModal
              sevaData={null}
              addSeva={addSeva}
              editSeva={editSeva}
              onClose={() => setIsAddModalOpen(false)}
            />
          )}
          {showModal && selectedSeva && (
            <AddSevaModal
              sevaData={selectedSeva}
              addSeva={addSeva}
              editSeva={editSeva}
              onClose={closeEditModal}
            />
          )}
          {isConfirmModalOpen && (
            <ConfirmModal
              message={`Are you sure you want to delete this Seva?`}
              onConfirm={confirmDelete}
              onCancel={() => setIsConfirmModalOpen(false)}
            />
          )}
          <div className="grid-wrapper shadow-lg my-5 ml-0 w-full sm:w-[98%]">
            {loading ? (
              <Skeleton
                shape={"rectangle"}
                style={{
                  width: "100%",
                  height: 800,
                }}
              />
            ) : (
              <>
                <div className="hidden lg:block">
                  {rows.length > 0 ? (
                    <Grid
                      data={processedData.data}
                      skip={page.skip}
                      take={page.take}
                      total={rows.length}
                      pageable={{
                        buttonCount: 4,
                        pageSizes: [5, 10, 15, 20, "All"],
                        pageSizeValue: pageSizeValue,
                      }}
                      onPageChange={handlePageChange}
                      sortable={true}
                      sort={sort}
                      onSortChange={handleSortChange}
                    >
                      <Column
                        field="id"
                        title="ID"
                        style={columnStyles}
                        width={80}
                      />
                      <Column
                        field="seva_availability"
                        title="Availability"
                        width={150}
                        cell={(props) => (
                          <td>
                            {props.dataItem.seva_availability
                              ? "Available"
                              : "Not Available"}
                          </td>
                        )}
                      />
                      <Column
                        field="seva_photo"
                        title="Photo"
                        cell={(props) => (
                          <td>
                            <img
                              src={props.dataItem.seva_photo}
                              alt="seva"
                              className="w-12 h-12"
                            />
                          </td>
                        )}
                        style={columnStyles}
                      />
                      <Column
                        field="seva_name"
                        title="Name"
                        style={columnStyles}
                        width={200}
                      />
                      <Column
                        field="seva_description"
                        title="Description"
                        style={columnStyles}
                      />
                      <Column
                        field="seva_price"
                        title="Price"
                        style={columnStyles}
                        width={120}
                      />
                      <Column
                        title="Actions"
                        className="text-center"
                        headerCell={() => (
                          <th className="text-center">Actions</th>
                        )}
                        cell={(props) => (
                          <td className="flex flex-start">
                            <div className="iconContainer flex">
                              <span
                                className="k-icon k-font-icon k-i-edit text text-xl mr-2 cursor-pointer"
                                onClick={() => openEditModal(props.dataItem)}
                              ></span>
                              <span
                                className="k-icon k-font-icon k-i-delete text text-xl cursor-pointer"
                                onClick={() =>
                                  openDeleteConfirmation(props.dataItem)
                                }
                              ></span>
                            </div>
                          </td>
                        )}
                        style={columnStyles}
                        width={150}
                      />
                    </Grid>
                  ) : (
                    <p>No data available.</p>
                  )}
                </div>
                <div className="block lg:hidden">
                  {rows.length > 0 ? (
                    rows.map((seva) => (
                      <SevaCard
                        key={seva.id}
                        seva={seva}
                        onEdit={() => openEditModal(seva)}
                        onDelete={() => openDeleteConfirmation(seva)}
                      />
                    ))
                  ) : (
                    <p>No data available.</p>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="sevaAddbtn mt-5 ml-auto text-center">
            <Button
              className="bg-gray-900 text-[#FFFFFF] mb-10 mr-5 rounded-[0.375rem] w-48 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 pl-4 pr-4 pt-2 pb-2 hover:bg-gray-700 hover:border-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#000000] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md"
              onClick={openAddModal}
            >
              {BUTTONS.ADD}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SevaManagement;
