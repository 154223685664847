import axiosInstance from './axiosInstance';

const templeID = async () => {
  try {
    const userId = localStorage.getItem('userId');
    const templeID = localStorage.getItem('templeID');

    if (templeID) {
      return templeID;
    }

    const templeAdminResponse = await axiosInstance.get(`/api/v1/temple-admin/${userId}`);

    console.log('Temple Admin data retrived successfully', templeAdminResponse.data.data.temple_id);
    if (templeAdminResponse.data.statusCode === 200) {
      localStorage.setItem('templeID', templeAdminResponse.data.data.temple_id);
      return templeAdminResponse.data.data.temple_id;
    } else {
      console.error('Failed to create or authenticate user:', templeAdminResponse.data);
    }
  } catch (error) {
    console.error('Error fetching current User:', error)
  }
}

export default templeID
