import * as React from "react";
import { useState, useEffect } from "react";
import logoimg from "../../../images/dharmik_logo.png";
import { LABELS } from "../../../common/constant/const";
import { SideNav } from "../../../ui/sidebar";
import { NavItems } from "../../../common/navItems";
import { useLocation, useNavigate } from "react-router-dom";
import { ModeToggle } from "../../../ui/mode-toggle";

const DrawerRouterContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(location.pathname);

  useEffect(() => {
    setActiveItem(location.pathname);
  }, [location.pathname]);

  const handleItemClick = (href) => {
    setActiveItem(href);
    navigate(href);
  };

  return (
    <div className="space-y-4 py-4 flex-col items-center relative min-h-[100vh] flex justify-between">
      <div>
        <div className="w-56 flex items-center justify-around m-2.5">
          <img className="w-14 h-14" src={logoimg} alt="" />
          <h2 className="text-#673011">{LABELS.MY_DHARMIK}</h2>
        </div>
        <div className="px-3 py-2 flex items-center">
          <div className="flex-1">
            <SideNav
              className="text-background opacity-0 transition-all duration-300 group-hover:z-50 group-hover:ml-4 group-hover:rounded group-hover:bg-foreground group-hover:p-2 group-hover:opacity-100"
              items={NavItems.map((item) => ({
                ...item,
                className: `${item.className} ${
                  activeItem === item.href ? "bg-gray-300 dark:bg-gray-600" : ""
                }`,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </div>
        </div>
      </div>
      <div className=" bottom-10">
        <ModeToggle />
      </div>
    </div>
  );
};

export default DrawerRouterContainer;
