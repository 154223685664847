import React, { useEffect, useState } from "react";
import { process } from "@progress/kendo-data-query";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { CalendarDays, Menu, Search } from "lucide-react";
import { Input } from "../../ui/input";

import { format } from "date-fns";

import { Skeleton } from "../../ui/skeleton";
import axiosInstance from "../../common/constant/axiosInstance";
import DrawerRouterContainer from "../layouts/leftDrawer/DrawerRouterContainer";
import templeID from "./../../common/constant/temple-id";
import AddSevaModal from "./add-seva/index.js";
import EditSevaModal from "./edit-seva/index.js";
import { print } from "./print";
import { Button } from "../../ui/button";
import { Calendar } from "../../ui/calender";
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu";
import { cn } from "../../lib/utils";
import { BUTTONS, SUB_TITLES } from "../../common/constant/const";

import "./style.css";

const SevaList = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState({ skip: 0, take: 20 });
  const [pageSizeValue, setPageSizeValue] = useState();
  const [sort, setSort] = useState([]);
  const [templeId, setTempleId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [fromDate, setFromDate] = useState(
    new Date(Date.now() - 100 * 24 * 60 * 60 * 1000)
  );
  const [toDate, setToDate] = useState(new Date());
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedSeva, setSelectedSeva] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    fetchData();
  }, [templeId]);

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const fromDateStr = fromDate ? format(fromDate, "yyyy-MM-dd") : null;
    const toDateStr = toDate ? format(toDate, "yyyy-MM-dd") : null;

    const filtered = rows.filter((item) => {
      const name = item.user_seva_details.first_name.toLowerCase();
      const receiptNumber = item.user_seva_details.receipt_number.toLowerCase();
      const sevaDate = item.user_seva_details.seva_date;
      const status = item.user_seva_details.payment_status;

      const dateInRange =
        (!fromDateStr || sevaDate >= fromDateStr) &&
        (!toDateStr || sevaDate <= toDateStr);

      return (
        (name.includes(lowerCaseQuery) ||
          receiptNumber.includes(lowerCaseQuery)) &&
        dateInRange &&
        (!paymentStatus || status === paymentStatus)
      );
    });

    setFilteredData(filtered);
  }, [searchQuery, fromDate, toDate, paymentStatus]);

  useEffect(() => {
    const fetchTempleId = async () => {
      try {
        const fetchedTempleId = await templeID();
        setTempleId(fetchedTempleId);
        console.log("Fetched Temple ID:", fetchedTempleId);
      } catch (error) {
        console.error("Error fetching temple ID:", error);
      }
    };

    fetchTempleId();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (!templeId) return;
      const response = await axiosInstance.get(
        `/api/v1/seva-history/temple/${templeId}`
      );
      console.log(response.data.data);
      if (Array.isArray(response.data.data)) {
        setRows(response.data.data);
        setFilteredData(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
        console.error("Data format error: Response data is not an array");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleRefreshData = () => {
    fetchData();
  };

  const handleAddButtonClick = () => {
    setShowAddModal(true);
  };

  const openEditModal = (dataItem) => {
    setSelectedSeva(dataItem);
  };

  const openPrintModal = (dataItem) => {
    addPrint(dataItem.user_seva_details?.receipt_number);
  };

  const addPrint = async (receiptNumber) => {
    try {
      const response = await axiosInstance.get(
        `/api/v1/receipt-details/${receiptNumber}/`
      );
      const newRow = {
        user: response.data.data.user,
        user_seva: response.data.data.user_seva,
        user_seva_payment_detail: response.data.data.user_seva_payment_detail,
        user_seva_vivara: response.data.data.user_seva_vivara,
      };
      print([newRow]);
    } catch (error) {
      console.error("Error while printing:", error);
    }
  };

  const handlePageChange = (event) => {
    const targetEvent = event.targetEvent;
    const take = targetEvent.value === "All" ? rows.length : event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleSortChange = (event) => {
    setSort(event.sort);
  };

  const processedData = process(filteredData, {
    skip: page.skip,
    take: page.take,
    sort,
  });

  const columnStyles = {
    color: "#673011",
    borderTop: "1px solid #000",
  };

  const TimeCell = (props) => {
    const time = props.dataItem.user_seva_details.seva_time;
    const formattedTime = `${time.hours
      .toString()
      .padStart(2, "0")}:${time.minutes.toString().padStart(2, "0")}`;
    return <td>{formattedTime}</td>;
  };

  const handleClear = () => {
    setSearchQuery("");
    setFromDate(new Date(Date.now() - 100 * 24 * 60 * 60 * 1000));
    setToDate(new Date());
    setPaymentStatus("");
  };

  return (
    <div>
      <div className="w-full flex  flex-col md:flex-row  items-start">
        <div className="w-full md:w-1/5 flex flex-col items-start sticky top-0">
          <div className="md:hidden flex justify-between items-center w-full mb-4">
            <Button
              onClick={toggleMenu}
              className="text-[#000000] dark:text-[#ffffff]"
            >
              <Menu />
            </Button>
          </div>
          <div
            className={`flex-col items-start w-full ${
              isMenuOpen ? "flex" : "hidden"
            } md:flex`}
          >
            <DrawerRouterContainer />
          </div>
        </div>
        <div className="w-full md:w-[79%] xl:flex sm:hidden flex-col items-end mt-[20px]">
          {loading ? (
            <Skeleton
              shape={"rectangle"}
              className="mt-5 mr-5 w-full h-[800px]"
            />
          ) : showAddModal ? (
            <div>
              <AddSevaModal
                onClose={() => setShowAddModal(false)}
                onRefresh={handleRefreshData}
                addPrint={addPrint}
              />
            </div>
          ) : (
            <div>
              <Grid
                resizable
                data={processedData.data}
                skip={page.skip}
                take={page.take}
                total={filteredData.length}
                pageable={{
                  buttonCount: 4,
                  pageSizes: [5, 10, 15, 20, "All"],
                  pageSizeValue: pageSizeValue,
                }}
                onPageChange={handlePageChange}
                sortable={true}
                sort={sort}
                onSortChange={handleSortChange}
              >
                <GridToolbar>
                  <span>{SUB_TITLES.PAYMENT_STATUS}</span>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline">
                        {paymentStatus || "Select Status"}
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-40 bg-[#FFFFFF] dark:bg-[#2d2d2d] text-[#000000] dark:text-[#ffffff] ">
                      <DropdownMenuLabel>
                        {SUB_TITLES.PAYMENT_STATUS}
                      </DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      {["success", "pending", "failure"].map((status) => (
                        <DropdownMenuCheckboxItem
                          key={status}
                          checked={paymentStatus === status}
                          onCheckedChange={() => setPaymentStatus(status)}
                        >
                          {status.charAt(0).toUpperCase() + status.slice(1)}
                        </DropdownMenuCheckboxItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>

                  <span>{SUB_TITLES.FROM}</span>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "w-[240px] justify-start text-left font-normal",
                          !fromDate && "text-muted-foreground"
                        )}
                      >
                        <CalendarDays className="mr-2 h-4 w-4" />
                        {fromDate ? (
                          format(fromDate, "PPP")
                        ) : (
                          <span>Pick a date</span>
                        )}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0 items-start bg-[#FFFFFF] dark:bg-[#2d2d2d] text-[#2d2d2d] dark:text-[#FFFFFF]">
                      <Calendar
                        mode="single"
                        selected={fromDate}
                        onSelect={(date) => {
                          setFromDate(date);
                        }}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>

                  <span>{SUB_TITLES.TO}</span>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "w-[240px] justify-start text-left font-normal",
                          !toDate && "text-muted-foreground"
                        )}
                      >
                        <CalendarDays className="mr-2 h-4 w-4" />
                        {toDate ? (
                          format(toDate, "PPP")
                        ) : (
                          <span>{SUB_TITLES.PICK_A_DATE}</span>
                        )}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0 items-start bg-[#FFFFFF] dark:bg-[#2d2d2d] text-[#2d2d2d] dark:text-[#FFFFFF]">
                      <Calendar
                        mode="single"
                        selected={toDate}
                        onSelect={(date) => {
                          setToDate(date);
                        }}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>

                  <div className="w-[44%] flex justify-around items-center relative flex-1 md:grow-0">
                    <Search className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
                    <Input
                      type="search"
                      placeholder="Search by Receipt name or ID"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="text-md placeholder-[#000000] w-[300px] p-2 pl-10 rounded-lg border border-[#000000] shadow-sm dark:bg-[#2d2d2d] dark:text-[#FFFFFF] dark:border-[#FFFFFF] placeholder-[#121212] dark:placeholder-[#FFFFFF] mr-3"
                    />

                    <Button
                      className="bg-gray-900 text-[#FFFFFF] mr-5 rounded-[0.375rem] w-48 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#000000] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md"
                      onClick={handleClear}
                    >
                      {BUTTONS.CLEAR}
                    </Button>
                  </div>
                </GridToolbar>
                <Column
                  field="user_seva_details.first_name"
                  title="Name"
                  style={columnStyles}
                />
                <Column
                  field="user_seva_details.receipt_number"
                  title="Receipt ID"
                  style={columnStyles}
                  width={200}
                />
                <Column
                  field="user_seva_details.seva_date"
                  title="Date"
                  style={columnStyles}
                />
                <Column
                  field="user_seva_details.seva_time"
                  title="Time"
                  style={columnStyles}
                  cell={TimeCell}
                />
                <Column
                  field="user_seva_details.total_amount"
                  title="Amount"
                  style={columnStyles}
                />
                <Column
                  field="user_seva_details.payment_status"
                  title="Status"
                  style={columnStyles}
                />
                <Column
                  field="user_seva_details.payment_type"
                  title="Type"
                  style={columnStyles}
                />
                <Column
                  field="user_seva_details.payment_provider"
                  title="Provider"
                  style={columnStyles}
                />
                <Column
                  title="Actions"
                  cell={(props) => (
                    <td>
                      <div className="w-[50%] flex justify-center">
                        <span
                          className="k-icon k-font-icon k-i-edit text-xl mr-2.5 cursor-pointer"
                          onClick={() => openEditModal(props.dataItem)}
                        ></span>
                        <span
                          className="k-icon k-font-icon k-i-print text-xl mr-2.5 cursor-pointer"
                          onClick={() => openPrintModal(props.dataItem)}
                        ></span>
                      </div>
                    </td>
                  )}
                  style={columnStyles}
                />
              </Grid>
              {selectedSeva && (
                <div className="z-[9999] bg-[#000000] bg-opacity-50 absolute top-0 left-0 w-full h-screen flex justify-center items-center">
                  <EditSevaModal
                    sevaData={selectedSeva}
                    onClose={() => setSelectedSeva(null)}
                    onRefresh={handleRefreshData}
                    addPrint={addPrint}
                  />
                </div>
              )}
            </div>
          )}
          {!showAddModal && (
            <div>
              <Button
                className="mt-5 fixed bottom-4 right-4 shadow-lg bg-gray-900 text-[#FFFFFF] mr-5 rounded-[0.375rem] w-48 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#000000] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md"
                onClick={handleAddButtonClick}
              >
                {BUTTONS.ADD}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SevaList;
