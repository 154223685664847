import { Dialog } from '@progress/kendo-react-dialogs';

import React from 'react';

import {BUTTONS } from '../../common/constant/const';
import { Button } from '../../ui/button';


const ConfirmModal = ({ message, onConfirm, onCancel }) => {
  return (
    <Dialog title="Confirm" onClose={onCancel} 
    width={450}>
      <div>{message}</div>
      <div className="mt-5 flex justify-end">
        <Button
          onClick={onCancel}
          className="bg-gray-900 text-[#FFFFFF] mb-10 mr-5 rounded-[0.375rem] w-48 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#000000] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md"  >         
          {BUTTONS.CANCEL}
        </Button>
        <button
          onClick={onConfirm}
          className="bg-gray-900 text-[#FFFFFF] mb-10 mr-5 rounded-[0.375rem] w-48 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#000000] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md"  >         
          {BUTTONS.CONFIRM}
        </button>
      </div>
    </Dialog>
  );
};

export default ConfirmModal;
