export const LABELS = {
    MY_DHARMIK: 'MY DHARMIK',
    NO_DATA_FOUND: 'No data found',
    EDIT_SEVA_OFFERING: 'Edit Seva Offering',
    DAILY_ACTIVE_USERS: 'Daily Active Users',
    DAILY_POOJA_SEVA_REVENUE: 'Daily Pooja Seva Revenue',
    DASHBOARD: 'Dashboard',
    SEVALIST:'Seva list',
    SEVA_MANAGEMENT:'Seva management',
    EVENTS:'Events',
    ABOUT_TEMPLE:'About Temple',
    THEMES:'Themes',
    LOGOUT:'Logout',
};

export const SUB_TITLES = {
    EVENT_NAME: 'Event Name:',
    EVENT_DATE: 'Event Date:',
    EVENT_TIME: 'Event Time:',
    EVENT_COMMENT: 'Event Comment:',
    EVENT_LOCATION:'Location URL:',
    SELECT_STATUS:'Select Status',
    EVENT_PHOTO:'Event Photo',
    EVENT_INVITATION:'Event Invitation',
    EVENT_URL: 'Event URL:',
    TOTAL_AMOUNT: 'Total Amount',
    SEVA_DATE: 'Seva Date',
    SEVA_TIME: 'Seva Time',
    PAYMENT_TYPE: 'Payment Type',
    PAYMENT_STATUS: 'Payment Status',
    PAY_AT_TEMPLE: 'Pay At Temple',
    SEVA_REASON: 'Seva Reason',
    SEVA_STATUS: 'Seva Status',
    RECEIPT_NUMBER: 'Receipt Number:',
    USER_NAME: 'User Name:',
    SEVA_AVAILABILITY: 'Seva Availability:',
    SEVA_AMOUNT: 'Seva Amount:',
    SEVA_COMMENT: 'Seva Comment:',
    MONTHS: 'Months',
    FROM: 'From:',
    TO: 'To:',
    PICK_A_DATE: 'Pick a date',
    SEVA_NAME: 'Seva Name:',
    SEVA_PHOTO:'Seva Photo'
};

export const DESCRIPTION = {};

export const FORMATS = {};

export const BUTTONS = {
    MONTHLY_ACTIVE_USERS: 'Monthly Active Users',
    SIGN_IN_WITH_GOOGLE: 'Sign in with Google',
    ADD: 'Add',
    CLEAR: 'Clear',
    CLOSE: 'Close',
    CANCEL: 'Cancel',
    CONFIRM: 'CONFIRM',
    ADD_SEVA: 'Add Seva',
    ADD_DEVOTEE_DETAILS: 'Add Devotee Details',
    PRINT: ' Print',
    SUBMIT: ' Submit',
    SAVE: 'Save'
}