import axios from 'axios';
import { getAuth } from 'firebase/auth';

const baseURL = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
	baseURL: baseURL
});

axiosInstance.interceptors.request.use(async (config) => {
	try {
		const auth = getAuth();
		const user = auth.currentUser;
		if (user) {
			const token = await user.getIdToken();
			config.headers.authorization = `Bearer ${token}`;
		} else {
			throw new Error('User is not authenticated.');
		}
	} catch (error) {
		console.error('Error getting authentication token:', error);
		throw error;
	}
	return config;
}, (error) => {
	console.error('Error intercepting request:', error);
	return Promise.reject(error);
});

export default axiosInstance;
