import React, { useState } from "react";
import { Card } from "@progress/kendo-react-layout";
import { Menu } from "lucide-react";

import { Button } from "../../ui/button";
import DrawerRouterContainer from "../layouts/leftDrawer/DrawerRouterContainer";
import DailyActiveUsers from "./DailyActiveUsers";
import DailySevaRevenue from "./DailySevaRevenue";
import { BUTTONS } from "../../common/constant/const";

const Dashboard = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="relative min-h-screen">
      <div className="w-full flex flex-col lg:flex-row justify-evenly items-start">
        <div className="w-full sm:w-2/5 lg:w-1/4 flex flex-col items-start sticky top-0 z-50">
          <div className="lg:hidden flex justify-between items-center w-full mb-4">
            <Button
              onClick={toggleMenu}
              className="text-[#000000] dark:text-[#ffffff]"
            >
              <Menu />
            </Button>
          </div>
          <div
            className={`fixed inset-0 z-40 bg-[#000000] bg-opacity-50 transition-opacity lg:hidden ${
              isMenuOpen ? "opacity-100" : "opacity-0 pointer-events-none"
            }`}
            onClick={toggleMenu}
          ></div>
          <div
            className={`fixed inset-y-0 left-0 z-50 bg-[#ffffff] dark:bg-[#000000] w-4/5 max-w-xs transform transition-transform duration-300 lg:relative lg:translate-x-0 lg:flex lg:w-full lg:max-w-none ${
              isMenuOpen ? "translate-x-0" : "-translate-x-full"
            }`}
          >
            <DrawerRouterContainer />
          </div>
        </div>
        <div
          className={`w-full lg:w-3/4 flex flex-col items-center ${
            isMenuOpen ? "blur-md lg:blur-none" : ""
          }`}
        >
          <Button
            className="text-[#ffffff] bg-[#000000] m-5 hover:text-[#000000] dark:text-[#ffffff] dark:hover:text-[#000000] dark:hover:bg-[#ffffff]"
            style={{ boxShadow: "1px 1px 2px 0px #565656" }}
          >
            {BUTTONS.MONTHLY_ACTIVE_USERS}
          </Button>
          <div className="w-full flex flex-col lg:flex-row items-center justify-evenly space-y-4 lg:space-y-0">
            <Card className="shadow-xl w-full sm:w-4/5 lg:w-2/5 p-4">
              <DailyActiveUsers />
            </Card>
            <Card className="shadow-xl w-full sm:w-4/5 lg:w-2/5 p-4">
              <DailySevaRevenue />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
